<template>
<div class="NotFoundPage">
  
  404
  
</div>
</template>

<script>
export default {
  name: "NotFoundPage",
}
</script>

<style lang="scss" scoped>
.NotFoundPage {
  //
}
</style>